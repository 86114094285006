import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root',
})
export class CsvExporterService {
  exportRiskAsCSV(data: string[], riskRef: string) {
    const blob = new Blob([data.join('\n')], {
      type: 'text/csv;charset=utf-8;',
    });

    saveAs(blob, `${riskRef}_${new Date().toDateString()}.csv`);
  }
}
