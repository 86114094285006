<p-accordionTab class="w-full">
  <ng-template pTemplate="header">
    <div class="flex flex-row gap-2 items-center">
      <p-inputSwitch [ngModel]="layerService.isLayerVisible(layerGroup())" [disabled]="!layerGroup().canActivate"
                     (onChange)="changeVisibility(layerGroup().layer, layerGroup().visible, $event)" class="small-input-switch"/>
      @if(layerGroup().hasRemoveFn) {
        <button (click)="layerGroup().removeFn($event)">
          <span><i class="fa fa-trash fa"></i></span>
        </button>
      }
      <span class="layer-title">{{layerGroup().title}}</span>
    </div>
  </ng-template>

  <div class="dc-accordion-item-body" role="region">
    <div cdkDropList [cdkDropListData]="layerGroup().layer" (cdkDropListDropped)="drop($event)" class="layers">
      @for (layer of layerGroup().children.slice().reverse(); track layer().title) {
        @switch (layer().type) {
          @case ('group') {
           <p-accordion cdkDrag >
              <app-layer-group [hasParent]="true" [layerGroup]="layer()" [map]="map()"></app-layer-group>
            </p-accordion>
          }
          @case ('vector') {
            <app-vector-layer cdkDrag [cdkDragData]="layer().layer" [layerGroup]="layerGroup()"
                              [layer]="layer()"></app-vector-layer>
          }
          @case ('image') {
            <app-image-layer cdkDrag [cdkDragData]="layer().layer" [layerGroup]="layerGroup()"
                             [layer]="layer()"></app-image-layer>
          }
          @default { }
        }
      }
    </div>
  </div>
</p-accordionTab>
