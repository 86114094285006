import { environment } from '../../../../../environments/environment';

export class LayerConfig {
  static LAYERS: CategoryGroup[] = [
    {
      name: 'Administrative Boundaries',
      categories: [
        {
          name: '',
          layers: [
            {
              name: 'Human Settlement Areas',
              type: 'WMTS',
              url: `${environment.geoserverUrl}/mvp/gwc/service/wmts`,
              visibleByDefault: false,
              params: {
                LAYERS: 'mvp:hsa_reports',
                USE_WFS: true,
                ATTR: 'objectid,fma_name,lga_name,sub_name,col3,hsa_vers,shape_length,shape_area,filename_1pc_aep_cc,filename_1pc_aep',
                GEOM_FIELD: 'shape',
                WFS_URL: `${environment.geoserverUrl}/ows?service=WFS&acceptversions=2.0.0&typeName=mvp:hsa_reports&request=GetFeature&outputFormat=json&propertyName=`,
              },
              legendURL: `${environment.geoserverUrl}/mvp/ows?service=WMS&request=GetLegendGraphic`,
            },
            {
              name: 'Sub-Catchments',
              type: 'WMTS',
              url: `${environment.geoserverUrl}/mvp/gwc/service/wmts`,
              visibleByDefault: false,
              params: {
                LAYERS: 'mvp:list_cfev_subcatchments_statewide',
                USE_WFS: true,
                ATTR: 'SUBCAT_ID,SC_NAME,SC_AREA,CAT_ID,CAT_NAME,SC_INFO,SHAPE_AREA,SHAPE_LEN',
                GEOM_FIELD: 'the_geom',
                WFS_URL: `${environment.geoserverUrl}/ows?service=WFS&acceptversions=2.0.0&typeName=mvp:list_cfev_subcatchments_statewide&request=GetFeature&outputFormat=json&propertyName=`,
              },
              legendURL: `${environment.geoserverUrl}/mvp/ows?service=WMS&request=GetLegendGraphic`,
            },
            {
              name: 'Local Government Areas',
              type: 'WMTS',
              url: `${environment.geoserverUrl}/mvp/gwc/service/wmts`,
              visibleByDefault: false,
              params: {
                LAYERS: 'mvp:lga_reports',
                USE_WFS: true,
                ATTR: 'gid,name,name2,plan_ref,lga_code,lga,file,source_att,shape_length,shape_area,filename_0pt5pc_aep,filename_lga_1pc_aep,filename_lga_1pc_cc_aep',
                GEOM_FIELD: 'shape',
                WFS_URL: `${environment.geoserverUrl}/ows?service=WFS&acceptversions=2.0.0&typeName=mvp:lga_reports&request=GetFeature&outputFormat=json&propertyName=`,
              },
              legendURL: `${environment.geoserverUrl}/mvp/ows?service=WMS&request=GetLegendGraphic`,
            },
          ],
        },
      ],
    },
    {
      name: 'Miscellaneous',
      categories: [
        {
          name: '',
          layers: [
            {
              name: 'Building Floor Heights',
              type: 'WMTS',
              url: `${environment.geoserverUrl}/mvp/gwc/service/wmts`,
              visibleByDefault: false,
              params: {
                LAYERS: 'mvp:buildings_floorheight_hh',
                USE_WFS: true,
                ATTR: 'build_id,build_ty,height,ufi,created_on,feat_rel,roof_const,wall_const,pid,pid_area,volume,folio,lpi,lse_lic_na,own_name,address,own_add1,own_add2,own_add3,address0,prop_add1,prop_add2,prop_add3,prop_no_f,prop_no_fs,prop_no_t,prop_no_ts,street,st_type,suburb,postcode,prop_floor,prop_name,municipali,tenure_ty,nomenclatu,cad_type1,cad_type2,meas_area,comp_area,con_year,prop_value,cap_value,aav,improv,prop_addre,prop_add00,prop_add01,owner_addr,owner_ad00,owner_ad01,owner_ad02,land_use_c,improvemen,constructi,land_area,building_s,bedroom_co,roof_const_1,wall_const_1,last_settl,build_id_int,objectid_1,fma_name,lga_name,sub_name,unq_name,hsa_vers,min_elev,max_elev,mean_elev,floor_height_synthetic,floor_height_measured,floor_height_riskmodel,pct90,bfrag_clas,hh_1pc,hh_1pc_cc,hh_halfpc,shape_length,shape_area',
                GEOM_FIELD: 'shape',
                WFS_URL: `${environment.geoserverUrl}/ows?service=WFS&acceptversions=2.0.0&typeName=mvp:buildings_floorheight_hh&request=GetFeature&outputFormat=json&propertyName=`,
              },
              legendURL: `${environment.geoserverUrl}/mvp/ows?service=WMS&request=GetLegendGraphic`,
            },
          ],
        },
      ],
    },
    {
      name: 'Base Data',
      categories: [
        {
          name: '',
          layers: [
            {
              name: 'HillshadeGrey',
              type: 'ArcGISRest',
              url: 'https://services.thelist.tas.gov.au/arcgis/rest/services/Basemaps/HillshadeGrey/MapServer',
              visibleByDefault: false,
              params: { LAYERS: 'show:1' },
            },
            {
              name: 'Cadastral Parcels',
              type: 'ArcGISRest',
              url: 'https://services.thelist.tas.gov.au/arcgis/rest/services/Public/CadastreParcels/MapServer',
              visibleByDefault: false,
              params: { LAYERS: 'show:0' },
            },
            {
              name: 'Community Sports and Recreation Facilities',
              type: 'ArcGISRest',
              url: 'https://services.thelist.tas.gov.au/arcgis/rest/services/Public/Infrastructure/MapServer',
              visibleByDefault: false,
              params: { LAYERS: 'show:8' },
            },
            {
              name: 'ACMA Communication Sites',
              type: 'ArcGISRest',
              url: 'https://services.thelist.tas.gov.au/arcgis/rest/services/Public/Infrastructure/MapServer',
              visibleByDefault: false,
              params: { LAYERS: 'show:43' },
            },
            {
              name: 'Sewer Network Structure',
              type: 'ArcGISRest',
              url: 'https://services.thelist.tas.gov.au/arcgis/rest/services/Public/Infrastructure/MapServer',
              visibleByDefault: true,
              params: { LAYERS: 'show:10' },
            },
            {
              name: 'Water Network Structure',
              type: 'ArcGISRest',
              url: 'https://services.thelist.tas.gov.au/arcgis/rest/services/Public/Infrastructure/MapServer',
              visibleByDefault: true,
              params: { LAYERS: 'show:9' },
            },
            {
              name: 'Alinta Facilities',
              type: 'WFS',
              url: `${environment.geoserverUrl}/mvp/ows?service=WFS&version=1.0.0&request=GetFeature&srsName=EPSG:3857&typeName=`,
              visibleByDefault: true,
              params: { LAYERS: 'mvp:alinta_facilities' },
            },
            {
              name: 'Aurora Sites',
              type: 'WFS',
              url: `${environment.geoserverUrl}/mvp/ows?service=WFS&version=1.0.0&request=GetFeature&srsName=EPSG:3857&typeName=`,
              visibleByDefault: true,
              params: { LAYERS: 'mvp:aurora_sites' },
            },
            {
              name: 'Bridges',
              type: 'WMTS',
              url: `${environment.geoserverUrl}/mvp/gwc/service/wmts`,
              visibleByDefault: true,
              params: {
                LAYERS: 'mvp:Bridge_Lines',
                USE_WFS: true,
                BBOX: true,
                //ATTR: 'OBJECTID,road_id,contributor_id,jurisdiction_control,operator,date_created,date_modified,national_route,state_route,full_street_name,street_name,street_name_label,street_type,street_type_label,street_suffix,street_suffix_label,street_alias_name,street_alias_type,street_alias_suffix,feature_type,hierarchy,subtype,ground_relationship,lane_count,lane_description,one_way,status,surface,trafficability,travel_direction,speed,state,source,horizontal_accuracy,SHAPE_Length',
                ATTR: 'jurisdiction_control,full_street_name,hierarchy,one_way,status',
                GEOM_FIELD: 'geom',
                WFS_URL: `${environment.geoserverUrl}/ows?service=WFS&acceptversions=2.0.0&typeName=mvp:Bridge_Lines&request=GetFeature&outputFormat=json&propertyName=`,
              },
              legendURL: `${environment.geoserverUrl}/mvp/ows?service=WMS&request=GetLegendGraphic`,
            },
          ],
        },
      ],
    },
    {
      name: 'TFMP Risk Assessment',
      categories: [
        {
          name: '1pc AEP CC',
          layers: [
            {
              name: 'Flood Extent (Vector Result)',
              type: 'WFS',
              url: `${environment.geoserverUrl}/mvp/ows?service=WFS&acceptversions=2.0.0&&outputFormat=json&request=GetFeature&srsName=EPSG:3857&typeName=`,
              visibleByDefault: false,
              params: { LAYERS: 'mvp:flood_extent_statewide_1pc_aep_cc' },
            },
            {
              name: 'Exposure All',
              type: 'WMTS',
              url: `${environment.geoserverUrl}/mvp/gwc/service/wmts`,
              visibleByDefault: false,
              params: { LAYERS: 'mvp:E_ALL_1pc_AEP_CC' },
              legendURL: `${environment.geoserverUrl}/mvp/ows?service=WMS&request=GetLegendGraphic`,
            },
            {
              name: 'Exposure Hydraulic Hazard',
              type: 'WMTS',
              url: `${environment.geoserverUrl}/mvp/gwc/service/wmts`,
              visibleByDefault: false,
              params: {
                LAYERS: 'mvp:E_hydraulic_hazard_1pc_AEP_CC',
                MIN_ZOOM: 8.0,
              },
              legendURL: `${environment.geoserverUrl}/mvp/ows?service=WMS&request=GetLegendGraphic`,
            },
            {
              name: 'Vulnerability All',
              type: 'WMTS',
              url: `${environment.geoserverUrl}/mvp/gwc/service/wmts`,
              visibleByDefault: false,
              params: { LAYERS: 'mvp:V_ALL_1pc_AEP_CC', MIN_ZOOM: 8.0 },
              legendURL: `${environment.geoserverUrl}/mvp/ows?service=WMS&request=GetLegendGraphic`,
            },
            {
              name: 'Vulnerability Travel Time',
              type: 'WMTS',
              url: `${environment.geoserverUrl}/mvp/gwc/service/wmts`,
              visibleByDefault: false,
              params: {
                LAYERS: 'mvp:V_Travel_time_index_1pc_AEP_CC',
                MIN_ZOOM: 8.0,
              },
              legendURL: `${environment.geoserverUrl}/mvp/ows?service=WMS&request=GetLegendGraphic`,
            },
            {
              name: 'Risk',
              type: 'WMTS',
              url: `${environment.geoserverUrl}/mvp/gwc/service/wmts`,
              visibleByDefault: false,
              params: { LAYERS: 'mvp:RISK_1pc_AEP_CC', MIN_ZOOM: 8.0 },
              legendURL: `${environment.geoserverUrl}/mvp/ows?service=WMS&request=GetLegendGraphic`,
            },
            /*{
              name: 'Consequence Classified',
              type: 'WMTS',
              url: `${environment.geoserverUrl}/mvp/gwc/service/wmts`,
              visibleByDefault: false,
              params: { LAYERS: 'mvp:C_classified_10pt_1pc_AEP_CC' },
              legendURL:
                `${environment.geoserverUrl}/mvp/ows?service=WMS&request=GetLegendGraphic`,
            },*/
            {
              name: 'Consequence C_E_x_V',
              type: 'WMTS',
              url: `${environment.geoserverUrl}/mvp/gwc/service/wmts`,
              visibleByDefault: false,
              params: { LAYERS: 'mvp:C_E_x_V_1pc_AEP_CC', MIN_ZOOM: 8.0 },
              legendURL: `${environment.geoserverUrl}/mvp/ows?service=WMS&request=GetLegendGraphic`,
            },
            {
              name: 'Resiliance Adri',
              type: 'WMTS',
              url: `${environment.geoserverUrl}/mvp/gwc/service/wmts`,
              visibleByDefault: false,
              params: { LAYERS: 'mvp:R_ADRI_1pc_AEP_CC', MIN_ZOOM: 8.0 },
              legendURL: `${environment.geoserverUrl}/mvp/ows?service=WMS&request=GetLegendGraphic`,
            },
            {
              name: 'Component Profile % Exposure',
              type: 'WMTS',
              url: `${environment.geoserverUrl}/mvp/gwc/service/wmts`,
              visibleByDefault: false,
              params: {
                LAYERS: 'mvp:Percent_exposure_1pc_AEP_CC',
                MIN_ZOOM: 8.0,
              },
              legendURL: `${environment.geoserverUrl}/mvp/ows?service=WMS&request=GetLegendGraphic`,
            },
            {
              name: 'Component Profile % Flood Exposure',
              type: 'WMTS',
              url: `${environment.geoserverUrl}/mvp/gwc/service/wmts`,
              visibleByDefault: false,
              params: {
                LAYERS: 'mvp:Percent_flood_exposure_1pc_AEP_CC',
                MIN_ZOOM: 8.0,
              },
              legendURL: `${environment.geoserverUrl}/mvp/ows?service=WMS&request=GetLegendGraphic`,
            },
            {
              name: 'Component Profile % Population Exposure',
              type: 'WMTS',
              url: `${environment.geoserverUrl}/mvp/gwc/service/wmts`,
              visibleByDefault: false,
              params: {
                LAYERS: 'mvp:Percent_popn_exposure_1pc_AEP_CC',
                MIN_ZOOM: 8.0,
              },
              legendURL: `${environment.geoserverUrl}/mvp/ows?service=WMS&request=GetLegendGraphic`,
            },

            {
              name: 'Component Profile % Human CI Vulnerability',
              type: 'WMTS',
              url: `${environment.geoserverUrl}/mvp/gwc/service/wmts`,
              visibleByDefault: false,
              params: {
                LAYERS: 'mvp:Percent_human_CI_vulnerability_1pc_AEP_CC',
                MIN_ZOOM: 9.5,
              },
              legendURL: `${environment.geoserverUrl}/mvp/ows?service=WMS&request=GetLegendGraphic`,
            },
            {
              name: 'Component Profile % Catchres Vulnerability',
              type: 'WMTS',
              url: `${environment.geoserverUrl}/mvp/gwc/service/wmts`,
              visibleByDefault: false,
              params: {
                LAYERS: 'mvp:Percent_catchres_vulnerability_1pc_AEP_CC',
                MIN_ZOOM: 9.5,
              },
              legendURL: `${environment.geoserverUrl}/mvp/ows?service=WMS&request=GetLegendGraphic`,
            },
            {
              name: 'Component Profile % Building Vulnerability',
              type: 'WMTS',
              url: `${environment.geoserverUrl}/mvp/gwc/service/wmts`,
              visibleByDefault: false,
              params: {
                LAYERS: 'mvp:Percent_building_vulnerability_1pc_AEP_CC',
                MIN_ZOOM: 16.5,
              },
              legendURL: `${environment.geoserverUrl}/mvp/ows?service=WMS&request=GetLegendGraphic`,
            },
          ],
        },
      ],
    },
  ];
}

export interface CategoryGroup {
  name: string;
  categories: CategoryConfig[];
}

export interface CategoryConfig {
  name: string;
  layers: LayerConfig[];
}

export interface LayerConfig {
  name: string;
  type: string;
  url: string;
  visibleByDefault: boolean;
  params?: Parameters;
  legendURL?: string;
}

export interface Parameters {
  LAYERS: string;
  MIN_ZOOM?: number;
  USE_WFS?: boolean;
  BBOX?: boolean;
  ATTR?: string;
  GEOM_FIELD?: string;
  WFS_URL?: string;
}
