import {
  effect,
  inject,
  Injectable,
  signal,
  Signal,
  WritableSignal,
} from '@angular/core';
import { Feature, Map as olMap, MapBrowserEvent, Overlay, View } from 'ol';
import TileLayer from 'ol/layer/Tile';
import { transform } from 'ol/proj';
import { TileArcGISRest, TileWMS, XYZ } from 'ol/source';
import VectorImageLayer from 'ol/layer/VectorImage';
import VectorSource from 'ol/source/Vector';
import { GeoJSON, WFS } from 'ol/format';
import { Layer, Tile as ATileLayer } from 'ol/layer';
import { FeatureLike } from 'ol/Feature';
import { add, Coordinate } from 'ol/coordinate';
import BaseLayer from 'ol/layer/Base';
import { Extent } from 'ol/extent';
import { Size } from 'ol/size';
import { LayerInfoDialogComponent } from '../../features/layer-info-dialog/layer-info-dialog.component';
import { LayerState } from '../models/layer-info-state.model';
import { EsriFeatureInfo } from '../models/esri-feature-info.model';
import { WmtsTileInfo } from '../models/wmts-tile-info.model';
import { RiskWizardService } from '../../features/risk-wizard-page/data-access/services/risk-wizard.service';
import { Geometry, LineString, MultiPolygon } from 'ol/geom';
import { environment } from '../../../../../environments/environment';
import { LayerConfig } from '../models/layer-config';
import { RasterInfoResponse } from '../models/raster-info-response.model';
import { convertToTitleCase } from '../../../../shared/utils/utils';
import { GeoserverOgcRequestBody } from '../models/geoserver-ogc-request-body.model';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';
import { DataFromGeoServer } from '../models/bridge-data.model';

@Injectable()
export class MapService {
  readonly INITIAL_ZOOM: number = 15.5 as const;

  private readonly httpClient: HttpClient = inject(HttpClient);
  public layerInfoState: WritableSignal<
    Map<string, WritableSignal<LayerState>>
  > = signal(new Map());
  public layerInfo: Map<string, WritableSignal<LayerState>> = new Map();
  public currentZoomLevel: number = this.INITIAL_ZOOM;
  public interactionType: WritableSignal<'Info' | 'Draw' | 'Select'> =
    signal('Info');
  public analysisZoneLayer?: Layer<any>;
  public map!: olMap;
  public retrievingDataFromService: boolean = false;
  public showRasterLayerData: boolean = false;
  public isPointSelect: boolean = false;

  readonly riskAssessmentLayers = LayerConfig.LAYERS.filter(
    (l) => l.name === 'TFMP Risk Assessment'
  );
  readonly riskWizardService: RiskWizardService = inject(RiskWizardService);
  private readonly initialCenter = [147.13683, -41.43478];
  private _overlay: Overlay;
  private popupContainerComponent: LayerInfoDialogComponent;

  private readonly _CONFIG = {
    headers: {
      accept: '*/*',
      'Content-Type': 'application/xml',
    },
  };

  private readonly _GEOSERVER_WFS = `${environment.apiUrl}geoserver/ows?service=WFS&acceptversions=2.0.0&typeName=`;
  private readonly _OUTPUT_FORMAT = `&request=GetFeature&outputFormat=application/json`;

  constructor() {
    this.riskWizardService.loadRisks();
    this.riskWizardService.clearExistingFeatureInfo();
    this.map = new olMap({
      layers: [
        new ATileLayer({
          source: new TileArcGISRest({
            url: 'https://services.thelist.tas.gov.au/arcgis/rest/services/Basemaps/TopographicGrayScale/MapServer/',
            params: { LAYERS: 'show:0' },
          }),
        }),
      ],
      view: new View({
        center: transform(this.initialCenter, 'EPSG:4326', 'EPSG:3857'),
        zoom: this.INITIAL_ZOOM,
        maxZoom: 23,
      }),
      controls: [],
    });

    effect(() => {
      this.riskWizardService.interactionType = this.interactionType();
    });
  }

  init(
    target: HTMLElement,
    overlayComponent: Signal<LayerInfoDialogComponent>
  ): olMap {
    this.map.setTarget(target);
    this.popupContainerComponent = overlayComponent();
    this._overlay = new Overlay({
      element: overlayComponent().popupContainer.nativeElement,
      autoPan: {
        animation: {
          duration: 250,
        },
      },
    });
    this.map.addOverlay(this._overlay);
    this.map.updateSize();
    return this.map;
  }

  getMap(): olMap {
    return this.map;
  }

  get overlay(): Overlay {
    return this._overlay;
  }

  get mapResolution(): number {
    return this.map.getView().getResolution();
  }

  get mapSize(): Size {
    return this.map.getSize();
  }

  get mapExtent(): Extent {
    return this.map.getView().calculateExtent(this.mapSize);
  }

  findCurrentZoomLevel() {
    this.map.getView().on('change:resolution', () => {
      this.currentZoomLevel = this.map.getView().getZoom()!;
    });
  }

  getArcGISRestLayer(
    lc: LayerConfig,
    addingLayer: boolean = false
  ): TileLayer<TileArcGISRest> {
    let { name, url, type, params, visibleByDefault } = lc;
    url = url.endsWith('/') ? `${url.substring(0, url.length - 1)}` : `${url}`;
    return new ATileLayer({
      source: new TileArcGISRest({
        url: url,
        params: params,
      }),
      visible: addingLayer ? true : visibleByDefault,
      properties: {
        ...params,
        title: name,
        legendURL: `${url}/legend?f=json`,
        sourceType: type,
      },
    });
  }

  getWFSLayer(
    lc: LayerConfig,
    addingLayer: boolean = false
  ): VectorImageLayer<any> {
    let { name, url, type, params, visibleByDefault } = lc;
    return new VectorImageLayer({
      source: new VectorSource({
        url: `${url}${params.LAYERS}`,
        format: new WFS(),
      }),
      visible: addingLayer ? true : visibleByDefault,
      properties: {
        title: name,
        sourceType: type,
      },
    });
  }

  getWMTSLayer(lc: LayerConfig, addingLayer: boolean = false): TileLayer<any> {
    let { name, type, url, params, legendURL, visibleByDefault } = lc;
    return new TileLayer({
      source: new XYZ({
        url: `${url}/rest/${params.LAYERS}/EPSG:900913/EPSG:900913:{z}/{y}/{x}?format=image/png`,
      }),
      visible: addingLayer ? true : visibleByDefault,
      minZoom: params.MIN_ZOOM,
      properties: {
        title: name,
        legendURL: `${legendURL}&format=image/png&layer=${params.LAYERS}`,
        sourceType: type,
        layerName: params.LAYERS,
        matrixSet: 'EPSG:900913',
        baseUrl: `${url}`,
        useWFS: params.USE_WFS,
        bboxMode: params.BBOX ? params.BBOX : false,
        wfsURL: params.WFS_URL,
        attrNames: params.ATTR,
        geomField: params.GEOM_FIELD,
      },
    });
  }

  async clickedOnMap() {
    this.map.on('click', ($event: MapBrowserEvent<any>) => {
      const cord: Coordinate = $event.coordinate;
      if (this.interactionType() === 'Select') {
        this.retrievingDataFromService = true;
        this.riskWizardService.clearExistingFeatureInfo();
        if (this.isPointSelect) {
          const url = `${this._GEOSERVER_WFS}mvp:Bridge_Lines&request=GetFeature&outputFormat=json`;
          this._getBridgeName(
            this._getTileFeatureInfoUsingWFSService(url, '', 'geom', true, cord)
          );
          this._getRasterInfoUsingWMS(cord);
        } else {
          this._getCoordinatesFromWMTSService({
            cord: cord,
            props: this.analysisZoneLayer?.getProperties(),
            tileGrid: this.analysisZoneLayer?.getSource().getTileGrid(),
          });
        }
      } else if (this.interactionType() === 'Info') {
        this.layerInfo = new Map();
        this.layerInfoState.set(this.layerInfo);
        let esriGeomObj: EsriFeatureInfo;
        let url: string;

        this._updateInfoState('Elevation', 'loading');

        url =
          'https://services.thelist.tas.gov.au/arcgis/rest/services/Raster/RasterMisc/MapServer/identify';
        esriGeomObj = {
          geometry: `{"x":${cord[0]},"y":${cord[1]}}`,
          geometryType: 'esriGeometryPoint',
          layerId: '73',
        };

        this._processArcGISMapServer(
          this.getDataFromArcGISMapServer(url, esriGeomObj),
          'Elevation',
          true
        );
        this.popupContainerComponent.resetDraggablePosition();
        this._overlay.setPosition(add(cord, [5, 5]));

        this.map.getLayers().forEach((subLayers: BaseLayer) => {
          if (subLayers.getProperties()['title'] != undefined) {
            subLayers.getLayersArray().forEach((layer: Layer<any>) => {
              if (layer.getVisible()) {
                const title = layer.get('title');
                const props: Object = layer.getProperties();
                if (props['sourceType'] === 'WFS') {
                  this._updateInfoState(title, 'loading');
                  let featuresLoaded = false;
                  this._updateInfoState(title, 'loading');
                  this.map.forEachFeatureAtPixel(
                    $event.pixel,
                    (feature: FeatureLike) => {
                      this._updateInfoState(
                        title,
                        'loaded',
                        this._generateFeatureDetails(feature)
                      );
                      featuresLoaded = true;
                    }
                  );
                  if (!featuresLoaded) {
                    this._updateInfoState(title, 'no data');
                  }
                } else if (props['sourceType'] === 'WMTS') {
                  console.log(cord);
                  if (props['useWFS']) {
                    console.log(props['bboxMode']);
                    this._updateInfoState(title, 'loading');
                    this._getTileDataFromWMTSService(
                      this._getTileFeatureInfoUsingWFSService(
                        props['wfsURL'],
                        props['attrNames'],
                        props['geomField'],
                        props['bboxMode'],
                        cord
                      ),
                      title
                    );
                  } else {
                    const source = layer.getSource();
                    const tileGrid = source.getTileGrid();

                    if (tileGrid) {
                      this._updateInfoState(title, 'loading');
                      this._getTileDataFromWMTSService(
                        this._getDataFromWMTSService({
                          cord: cord,
                          props: props,
                          tileGrid: tileGrid,
                        }),
                        title
                      );
                    }
                  }
                } else if (props['sourceType'] === 'ArcGISRest') {
                  this._updateInfoState(title, 'loading');
                  let layerId: string = layer
                    .getSource()
                    .getParams()
                    ['LAYERS'].toString();
                  layerId = layerId.includes('show')
                    ? layerId.split(':')[1]
                    : layerId;

                  url = `${layer.getSource().getUrls()[0]}/identify`;
                  esriGeomObj = {
                    geometry: `{"x":${cord[0]},"y":${cord[1]}}`,
                    geometryType: 'esriGeometryPoint',
                    layerId: layerId,
                  };

                  this._processArcGISMapServer(
                    this.getDataFromArcGISMapServer(url, esriGeomObj),
                    title,
                    false
                  );
                }
              }
            });
          }
        });
      }
    });
  }

  getBridgeData(coordinates: string): Promise<DataFromGeoServer> {
    //let wfsURL: string = `${this._GEOSERVER_WFS}mvp:Bridge_Lines&request=GetFeature&outputFormat=json&propertyName=${attr}&filter=<Filter><Within><PropertyName>geom</PropertyName><Polygon srsName="EPSG:3857"><exterior><LinearRing><posList>${coordinates}</posList></LinearRing></exterior></Polygon></Within></Filter>`;
    let wfsPostURL: string = `${this._GEOSERVER_WFS}mvp:Bridge_Lines${this._OUTPUT_FORMAT}`;

    const reqBody = this._getGeoServerPostRequestBody({
      layerName: 'mvp:Bridge_Lines',
      attributes: [
        'OBJECTID',
        'road_id',
        'contributor_id',
        'jurisdiction_control',
        'full_street_name',
        'hierarchy',
        'one_way',
        'status',
      ],
      geoPropName: 'geom',
      coordinates: coordinates.replaceAll(',', ' '),
    });

    return firstValueFrom(
      this.httpClient.post<DataFromGeoServer>(wfsPostURL, reqBody, this._CONFIG)
    );
  }

  getBuildingData(coordinates: string): Promise<DataFromGeoServer> {
    let wfsPostURL: string = `${this._GEOSERVER_WFS}mvp:buildings_floorheight_hh${this._OUTPUT_FORMAT}`;

    const reqBody = this._getGeoServerPostRequestBody({
      layerName: 'mvp:buildings_floorheight_hh',
      attributes: [
        'build_id',
        'build_ty',
        'height',
        'ufi',
        'created_on',
        'feat_rel',
        'roof_const',
        'wall_const',
        'pid',
        'pid_area',
        'volume',
        'folio',
        'lpi',
        'lse_lic_na',
        'own_name',
        'address',
        'hh_1pc',
      ],
      geoPropName: 'shape',
      coordinates: coordinates.replaceAll(',', ' '),
    });

    return firstValueFrom(
      this.httpClient.post<DataFromGeoServer>(wfsPostURL, reqBody, this._CONFIG)
    );
  }
  getDataFromArcGISMapServer(
    url: string,
    params: EsriFeatureInfo
  ): Promise<Response> {
    const urlParams: URLSearchParams = new URLSearchParams({
      f: 'json',
      geometry: params.geometry,
      geometryType: params.geometryType,
      sr: '3857',
      layers: `all:${params.layerId}`,
      tolerance: '10',
      mapExtent: this.mapExtent.toString(),
      imageDisplay: `${this.mapSize.toString()}, 96`,
      returnGeometry: 'false',
    });

    return fetch(`${url}?${urlParams}`);
  }

  async _getBridgeName(dataFromWMTSService: Promise<Response>) {
    const response = await dataFromWMTSService;
    const data = await response.json();
    if (data.features.length > 0) {
      const feature: Feature<Geometry> = new GeoJSON().readFeature(
        data.features[0]
      );
      //TODO
      this.riskWizardService.hazardSource.addFeature(feature);
      this.riskWizardService.coordinates.push(
        (feature.getGeometry() as LineString).getCoordinates()
      );
      this.riskWizardService.hazardBoundary = new Feature(
        new LineString(this.riskWizardService.coordinates)
      );

      this.riskWizardService.bridgeName = `${convertToTitleCase(
        data.features[0].properties.full_street_name
      )} Bridge`;
    }
  }

  async _getRasterInfoUsingWMS(cord: Coordinate) {
    this.riskWizardService.rasterInfo = [];
    this.showRasterLayerData = false;
    const res = this.map.getView().getResolution();
    const minX = cord[0] - 0.005;
    const maxX = cord[0] + 0.005;
    const minY = cord[1] - 0.005;
    const maxY = cord[1] + 0.005;
    const bbox = `BBOX=${minX},${minY},${maxX},${maxY}`;

    const iMinX = cord[0] - 0.025;
    const iMaxX = cord[0] + 0.025;
    const iMinY = cord[1] - 0.025;
    const iMaxY = cord[1] + 0.025;
    const ibbox = `BBOX=${iMinX},${iMinY},${iMaxX},${iMaxY}`;
    const regex: RegExp = /BBOX=(-?[0-9]*.[0-9]*,?)*/g;
    for (const l1 of this.riskAssessmentLayers[0].categories[0].layers.map(
      (l) => ({ name: l.name, layer: l.params['LAYERS'] })
    )) {
      const tileSource = new TileWMS({
        url: `${environment.geoserverUrl}/mvp/wms`,
        params: {
          LAYERS: l1.layer,
          TILED: true,
        },
        serverType: 'geoserver',
      });
      let url = tileSource.getFeatureInfoUrl(cord, res, 'EPSG:3857', {
        INFO_FORMAT: 'application/json',
      });
      const infoUrl = url.replaceAll(regex, bbox);
      const getMapUrl = url
        .replaceAll('REQUEST=GetFeatureInfo', 'REQUEST=GetMap')
        .replaceAll('&INFO_FORMAT=application/json', '')
        .replaceAll(regex, ibbox);

      const response: Response = await fetch(infoUrl);
      const responseJson: RasterInfoResponse = await response.json();

      console.log(getMapUrl);
      if (responseJson.features.length > 0) {
        this.riskWizardService.rasterInfo.push({
          layer: l1.name,
          value: responseJson.features[0].properties.GRAY_INDEX,
          imgUrl: getMapUrl,
        });
      }
    }
    this.retrievingDataFromService = false;
    this.showRasterLayerData = true;
  }

  async _getCoordinatesFromWMTSService(params: WmtsTileInfo) {
    try {
      let res = await this._getDataFromWMTSService(params);
      let data = await res.json();

      if (data.features.length > 0) {
        this.retrievingDataFromService = false;
        const feature: Feature<Geometry> = new GeoJSON().readFeature(
          data.features[0]
        );
        const existing: Feature<Geometry> =
          this.riskWizardService.hazardSource.getFeatureById(feature.getId());
        if (existing) {
          this.riskWizardService.hazardSource.removeFeature(existing);
          console.log(
            (existing.getGeometry() as MultiPolygon).getCoordinates()
          );
        } else {
          this.riskWizardService.hazardSource.addFeature(feature);
        }
        //TODO
        this.riskWizardService.coordinates = [];
        this.riskWizardService.hazardSource.getFeatures().forEach((feature) => {
          this.riskWizardService.coordinates.push(
            (feature.getGeometry() as MultiPolygon).getCoordinates()[0]
          );
        });
        if (!this.riskWizardService.hazardBoundary) {
          this.riskWizardService.hazardBoundary = new Feature(
            new MultiPolygon(this.riskWizardService.coordinates)
          );
        } else {
          this.riskWizardService.hazardBoundary
            .getGeometry()
            .setCoordinates(this.riskWizardService.coordinates);
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  _generateFeatureDetails(feature: FeatureLike): any[] {
    const details: any[] = [];
    const selectionKeys: string[] = (feature as Feature).getKeys();
    selectionKeys.forEach((key) => {
      details[key] = feature.get(key);
    });

    return details;
  }

  _getDataFromWMTSService(params: WmtsTileInfo): Promise<any> {
    const tileCord = params.tileGrid.getTileCoordForCoordAndResolution(
      params.cord,
      this.mapResolution
    );
    const tileExtent = params.tileGrid.getTileCoordExtent(tileCord);
    const x: number = Math.floor(
      (params.cord[0] - tileExtent[0]) / this.mapResolution
    );
    const y: number = Math.floor(
      (tileExtent[3] - params.cord[1]) / this.mapResolution
    );

    const urlParams: URLSearchParams = new URLSearchParams({
      SERVICE: 'WMTS',
      REQUEST: 'GetFeatureInfo',
      VERSION: '1.0.0',
      LAYER: params.props['layerName'],
      STYLE: '',
      TILEMATRIXSET: params.props['matrixSet'],
      TILEMATRIX: `${params.props['matrixSet']}:${tileCord[0]}`,
      TILEROW: tileCord[2].toString(),
      TILECOL: tileCord[1].toString(),
      INFOFORMAT: 'application/json',
      I: x.toString(),
      J: y.toString(),
    });

    return fetch(`${params.props['baseUrl']}?${urlParams}`);
  }

  async _getTileFeatureInfoUsingWFSService(
    wfsURL: string,
    attrNames: string,
    geomField: string,
    bboxMode: boolean,
    cord: Coordinate
  ): Promise<Response> {
    if (bboxMode) {
      const bufferSize: number = 5;
      const minX: number = cord[0] - bufferSize;
      const minY: number = cord[1] - bufferSize;
      const maxX: number = cord[0] + bufferSize;
      const maxY: number = cord[1] + bufferSize;

      return fetch(
        `${wfsURL}${attrNames}&bbox=${minX}, ${minY}, ${maxX}, ${maxY}, EPSG:3857`
      );
    }
    return fetch(
      `${wfsURL}${attrNames}&filter=<Filter><Intersects><PropertyName>${geomField}</PropertyName><Point srsName='EPSG:3857'><coordinates>${cord[0]},${cord[1]}</coordinates></Point></Intersects></Filter>`
    );
  }

  async _getTileDataFromWMTSService(
    dataFromWMTSService: Promise<Response>,
    title: string
  ): Promise<any> {
    try {
      const response = await dataFromWMTSService;
      const data = await response.json();
      if (data.features.length > 0) {
        this._updateInfoState(title, 'loaded', data.features[0].properties);
      } else {
        this._updateInfoState(title, 'no data');
      }
    } catch (e) {
      return console.log(e);
    }
  }

  async _processArcGISMapServer(
    infoDataFromArcGISMapServer: Promise<Response>,
    title: string,
    isElevation: boolean
  ): Promise<any> {
    try {
      const res: Response = await infoDataFromArcGISMapServer;
      const data = await res.json();
      if (data.results.length > 0) {
        let layerProps: any;
        if (isElevation) {
          layerProps = {
            Elevation: `${Math.round(
              parseFloat(data.results[0].attributes['Pixel Value'])
            )}m`,
          };
        } else {
          layerProps = data.results[0].attributes;
        }
        this._updateInfoState(title, 'loaded', layerProps);
      } else {
        this._updateInfoState(title, 'no data');
      }
    } catch (e) {
      return console.log(e);
    }
  }

  _updateInfoState(title: string, state: any, data?: object) {
    if (this.layerInfo.has(title)) {
      this.layerInfo.get(title).set({ state: state, data: data });
    } else {
      this.layerInfo.set(title, signal({ state: state, data: data }));
      this.layerInfoState.set(this.layerInfo);
    }
  }

  _getGeoServerPostRequestBody(details: GeoserverOgcRequestBody): string {
    let properties: string = '';
    if (details.attributes.length > 0) {
      properties = details.attributes
        .map((attr) => `<wfs:PropertyName>${attr}</wfs:PropertyName>`)
        .toString()
        .replaceAll(',', ' ');
    }
    console.log(properties);

    return `
        <wfs:GetFeature service="WFS" version="1.1.0" outputFormat="application/json" xmlns:wfs="http://www.opengis.net/wfs" xmlns:ogc="http://www.opengis.net/ogc" xmlns:gml="http://www.opengis.net/gml">
            <wfs:Query typeName="${details.layerName}" srsName="EPSG:3857">
                ${properties}
                <ogc:Filter>
                    <ogc:Or>
                        <ogc:Within>
                            <ogc:PropertyName>${details.geoPropName}</ogc:PropertyName>
                            <gml:Polygon srsName="EPSG:3857">
                                <gml:exterior>
                                    <gml:LinearRing>
                                        <gml:posList> ${details.coordinates} </gml:posList>
                                    </gml:LinearRing>
                                </gml:exterior>
                            </gml:Polygon>
                        </ogc:Within>
                        <ogc:Intersects>
                            <ogc:PropertyName>${details.geoPropName}</ogc:PropertyName>
                            <gml:Polygon srsName="EPSG:3857">
                                <gml:exterior>
                                    <gml:LinearRing>
                                        <gml:posList> ${details.coordinates} </gml:posList>
                                    </gml:LinearRing>
                                </gml:exterior>
                            </gml:Polygon>
                        </ogc:Intersects>
                    </ogc:Or>
                </ogc:Filter>
            </wfs:Query>
        </wfs:GetFeature>`;
  }
}
