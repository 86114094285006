import {
  Component,
  computed,
  effect,
  ElementRef,
  Inject,
  inject,
  OnInit,
  Renderer2,
  Signal,
  ViewChild,
} from '@angular/core';
import { TooltipDirective } from '../../../../../../shared/directives/tooltip/tooltip.directive';
import { DraggableDirective } from '../../../../../../shared/directives/draggable/draggable.directive';
import {
  MAT_BOTTOM_SHEET_DATA,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import { RiskTableService } from '../../data-access/risk-table.service';
import { SidebarService } from '../../../../ui/sidebar/data-access/sidebar.service';
import { toSignal } from '@angular/core/rxjs-interop';
import { of, switchMap } from 'rxjs';
import { Table, TableModule } from 'primeng/table';
import { RiskWizard } from '../../../risk-wizard-page/data-access/models/risk-wizard';
import { FormsModule } from '@angular/forms';
import { Button } from 'primeng/button';
import { Ripple } from 'primeng/ripple';
import { KeyValuePipe, NgStyle } from '@angular/common';
import {
  Risk,
  RiskDetail,
} from '../../../risk-wizard-page/data-access/models/risk-detail.model';
import { ViewEditRiskDetailComponent } from '../view-edit-risk-detail/view-edit-risk-detail.component';
import { ModalService } from '../../../../../../shared/services/modal/modal.service';
import { RiskInfoService } from '../../data-access/risk-info.service';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { MultiSelectModule } from 'primeng/multiselect';
import { CreateTreatmentComponent } from '../create-treatment/create-treatment.component';
import { InputTextModule } from 'primeng/inputtext';
import { AccordionModule } from 'primeng/accordion';
import { RiskWizardService } from '../../../risk-wizard-page/data-access/services/risk-wizard.service';

@Component({
  selector: 'app-risk-table',
  standalone: true,
  imports: [
    TooltipDirective,
    DraggableDirective,
    FormsModule,
    Button,
    Ripple,
    NgStyle,
    TableModule,
    IconFieldModule,
    InputIconModule,
    MultiSelectModule,
    InputTextModule,
    AccordionModule,
    KeyValuePipe,
  ],
  templateUrl: './risk-table.component.html',
  styleUrl: './risk-table.component.css',
})
export class RiskTableComponent implements OnInit {
  @ViewChild('draggableContainer', { static: true })
  draggableContainer: ElementRef<HTMLElement>;
  @ViewChild('riskTable', { static: true }) table: Table;

  sidebarOpening: Signal<boolean>;

  risks: RiskDetail[] = [];
  expandedRows = {};

  hazardTypes: string[] = RiskWizard.ALL.map((a) => a.name);
  analysisZones: string[] = RiskWizard.ANALYSIS_ZONES.map((a) => a.name);
  infrastructure: string[] = RiskWizard.INFRASTRUCTURE;
  impactArea: string[] = RiskWizard.IMPACT_AREA;
  feature: string[] = RiskWizard.FEATURE.map((a) => a.name);
  likelihood: string[] = RiskWizard.LIKELIHOOD_LEVEL;
  event: string[] = RiskWizard.EVENT;
  consequence: string[] = RiskWizard.CONSEQUENCE.map((a) => a.name);
  treatmentOpt: string[] = RiskWizard.TREATMENT;
  treatmentStrategy: string[] = RiskWizard.TREATMENT_STRATEGY;

  private bottomSheetRef: MatBottomSheetRef<RiskTableComponent> = inject(
    MatBottomSheetRef<RiskTableComponent>
  );
  readonly riskTableService: RiskTableService = inject(RiskTableService);
  readonly modalService: ModalService = inject(ModalService);
  readonly riskInfoService: RiskInfoService = inject(RiskInfoService);
  readonly riskWizardService: RiskWizardService = inject(RiskWizardService);
  readonly renderer: Renderer2 = inject(Renderer2);
  readonly sidebarService: SidebarService = null;

  tableHeight = computed(() => {
    return this.riskTableService.containerHeight() < 250
      ? '250px'
      : `${this.riskTableService.containerHeight() - 70}px`;
  });

  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any) {
    this.riskWizardService.allRisks$.subscribe(
      (next: Risk[]) => (this.risks = next.map((r) => r.data))
    );

    this.sidebarService = data.service;

    this.sidebarOpening = toSignal(
      this.sidebarService.drawer!.openedStart.pipe(switchMap(() => of(true))),
      { initialValue: false }
    );

    effect(() => {
      if (this.sidebarOpening()) this.bottomSheetRef.dismiss();
    });

    effect(() => {
      this.renderer.setStyle(
        this.draggableContainer.nativeElement,
        'height',
        `${this.riskTableService.containerHeight()}px`
      );
    });
  }

  ngOnInit() {
    this.table.onFilter.subscribe(() => {
      setTimeout(() => {
        this.riskTableService.containerHeight.set(
          this.riskTableService.containerHeight() + 1
        );
      });
    });

    if (this.riskTableService.filterMetaData) {
      Object.keys(this.riskTableService.filterMetaData).forEach((field) => {
        let filterValue = this.riskTableService.filterMetaData[field].value;
        let matchMode = this.riskTableService.filterMetaData[field].matchMode;
        this.table.filter(filterValue, field, matchMode);
      });
    }
  }

  onHeightChange(newHeight: number) {
    let maxHeight = window.innerHeight * 0.8;
    let minHeight = window.innerHeight * 0.25;

    if (minHeight >= newHeight) {
      return;
    }
    if (newHeight > maxHeight) {
      newHeight = maxHeight;
    }
    this.riskTableService.containerHeight.set(newHeight);
  }

  close(): void {
    this.bottomSheetRef.dismiss();
  }

  protected readonly RiskWizard = RiskWizard;

  exportToCSV() {
    //TODO
    if (this.table) {
      this.table.exportCSV();
    }
  }

  protected readonly console = console;

  expandAll() {
    this.expandedRows = this.risks.reduce(
      (acc, p: RiskDetail) => (acc[p.riskRef] = true) && acc,
      {}
    );
  }

  collapseAll() {
    this.expandedRows = {};
  }

  viewRiskDetail(risk: RiskDetail) {
    let component =
      this.modalService.showComponent<ViewEditRiskDetailComponent>(
        ViewEditRiskDetailComponent,
        { risk }
      );

    component.updateEvent.subscribe((event: any) => {
      if (event.create) {
        this.riskWizardService.updateData(
          event.model.id,
          JSON.stringify(event.model.data, null, 1)
        );
      }
    });
  }

  zoomToRiskLocation(risk: RiskDetail) {
    //TODO
    console.log(risk, risk);
  }

  riskTreatment(risk: RiskDetail) {
    let component = this.modalService.showComponent<CreateTreatmentComponent>(
      CreateTreatmentComponent,
      { risk },
      'custom-dialog-panel-background'
    );

    component.createEvent.subscribe((event: any) => {
      if (event.create) {
        this.riskWizardService.updateData(
          event.model.id,
          JSON.stringify(event.model.data, null, 1)
        );
      }
    });
  }
}
