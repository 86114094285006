<div class="content" xmlns="http://www.w3.org/1999/html">
  <form class="flex flex-col w-full max-w-3xl mx-auto" [formGroup]="form">
    <div id="selectionType" class="flex flex-row mt-4">
      @for (sT of selectionTypes; track sT) {
        <div class="flex align-baseline">
          <p-radioButton [inputId]="sT.type" [value]="sT" formControlName="selectionType"
                         [style]="{ width: '100%', 'margin-left': '1rem'}"/>
          <label [for]="sT.type" class="ml-2 radio-lbl">{{ sT.name }}</label>
        </div>
      }
    </div>
    @if (Object.keys(this.form.get('selectionType').value).length > 0) {
      @if (isFileUpload) {
        <div class="flex flex-row justify-evenly mt-4">
            <p-fileUpload mode="basic" name="file" chooseIcon="fa-solid fa-upload" url="" accept=".csv"
                      (onUpload)="upload($event)" [auto]="true" chooseLabel="Browse" />
        </div>
      } @else {
        <p-floatLabel>
          <p-dropdown formControlName="hazardType" [options]="hazards" optionLabel="name" optionDisabled="disabled"
                      id="hazardType" [style]="{ width: '100%', border: 'solid 1px black' }"/>
          <label for="hazardType">Hazard Type</label>
        </p-floatLabel>

        @if (this.form.get('hazardType').value !== null) {
          <p-floatLabel>
            <p-dropdown formControlName="event" [options]="events" id="event"
                        [style]="{ width: '100%', border: 'solid 1px black' }"/>
            <label for="event">Event</label>
          </p-floatLabel>
        }

        @if (this.form.get('event').value !== '') {
          <p-floatLabel>
            <p-dropdown formControlName="analysisZone" [options]="analysisZones" optionLabel="name" id="analysisZone"
                        [style]="{ width: '100%', border: 'solid 1px black' }"/>
            <label for="analysisZone">Area of Analysis</label>
          </p-floatLabel>
        }

        @if (this.form.get('analysisZone').value !== '') {
          <p-floatLabel>
            <p-multiSelect formControlName="infrastructure" [options]="infrastructures" id="infrastructure"
                        [style]="{ width: '100%', border: 'solid 1px black' }"/>
            <label for="infrastructure">Lifeline</label>
          </p-floatLabel>
        }

        @if (this.form.get('infrastructure').value !== null) {
          <p-floatLabel>
            <p-multiSelect formControlName="feature" [options]="features" optionLabel="name" optionDisabled="disabled"
                           readonly="true" disabled id="feature"
                           [style]="{ width: '100%', border: 'solid 1px black', background: '#efefef' }"/>
            <label for="hazardType">Feature Type(s)</label>
          </p-floatLabel>
        }
        @if (this.form.get('feature').value !== null) {
          <div class="mt-4 flex justify-evenly">
            <button class="bg-sky-600 hover:bg-sky-800 text-white py-2 px-4 border border-sky-600 rounded"
                    (click)="drawSelection()">
              <span class="fa-solid fa-draw-polygon"></span> Create Boundary
            </button>

            <button class="bg-sky-600 hover:bg-sky-800 text-white py-2 px-4 border border-sky-600 rounded"
                    (click)="boundarySelection()">
              <span class="fa-solid fa-hand-pointer"></span> Select Boundary
            </button>
          </div>
        }
      }
    }

  </form>
</div>

