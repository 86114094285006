import {
  Component,
  effect,
  EventEmitter,
  inject,
  Inject,
  Output,
  signal,
  Signal,
  WritableSignal,
} from '@angular/core';
import { MultiSelectChangeEvent, MultiSelectModule } from 'primeng/multiselect';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import {
  ImpactAreaRisDetails,
  InfrastructureDetail,
  InfrastructureDetails,
  Risk,
  RiskDetail,
} from '../../../risk-wizard-page/data-access/models/risk-detail.model';
import { RiskWizard } from '../../../risk-wizard-page/data-access/models/risk-wizard';
import { toSignal } from '@angular/core/rxjs-interop';
import { RiskInfoService } from '../../data-access/risk-info.service';
import { ModalService } from '../../../../../../shared/services/modal/modal.service';
import { FloatLabelModule } from 'primeng/floatlabel';
import { AccordionModule } from 'primeng/accordion';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputTextModule } from 'primeng/inputtext';
import { NgForOf } from '@angular/common';
import {
  BridgeProperties,
  BuildingProperties,
  MultiSelectLabel,
} from '../../../../data-access/models/bridge-data.model';
import { RiskWizardService } from '../../../risk-wizard-page/data-access/services/risk-wizard.service';
import { convertToTitleCase } from '../../../../../../shared/utils/utils';
import { CreateEvent } from '../../../risk-wizard-page/risk-wizard/create-event';
import { CsvExporterService } from '../../data-access/csv-exporter.service';

@Component({
  selector: 'app-view-edit-risk-detail',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    FormsModule,
    FloatLabelModule,
    AccordionModule,
    DropdownModule,
    InputTextareaModule,
    MultiSelectModule,
    InputTextModule,
    NgForOf,
  ],
  templateUrl: './view-edit-risk-detail.component.html',
  styleUrl: './view-edit-risk-detail.component.css',
})
export class ViewEditRiskDetailComponent {
  form: FormGroup;
  maxConsequences: string[] = [];
  mode: WritableSignal<'view' | 'edit'>;
  protected readonly RiskWizard = RiskWizard;
  protected multipleImpactedAreas: Map<string, string[]>;
  riskInfo: RiskDetail;
  readonly riskInfoService: RiskInfoService = inject(RiskInfoService);
  readonly riskWizardService: RiskWizardService = inject(RiskWizardService);
  readonly modalService: ModalService = inject(ModalService);
  readonly exporterService: CsvExporterService = inject(CsvExporterService);
  readonly formBuilder: FormBuilder = inject(FormBuilder);
  private readonly dialogRef: DialogRef<ViewEditRiskDetailComponent> = inject(
    DialogRef<ViewEditRiskDetailComponent>
  );

  @Output() updateEvent: EventEmitter<any> = new EventEmitter<any>();

  constructor(@Inject(DIALOG_DATA) protected data: { risk: RiskDetail }) {
    this.multipleImpactedAreas = new Map();
    this.mode = signal('view');
    this.riskInfo = data.risk;

    effect(() => {
      if (this.mode() === 'edit') {
        this.form.controls['hazardStatement'].enable();
        this.form.controls['consequenceStatement'].enable();
        this.form.controls['likelihood'].enable();
        (this.form.controls['infrastructure'] as FormArray).controls.forEach(
          (inf: FormGroup) => {
            let infCtrl = Object.values(inf.controls)[0] as FormGroup;
            infCtrl.controls['impactArea'].enable();
            (infCtrl.controls['impactedAreas'] as FormArray).controls.forEach(
              (group: FormGroup) => {
                let ctrl = Object.values(group.controls)[0] as FormGroup;
                ctrl.controls['consequence'].enable();
                ctrl.controls['structures'].enable();
                ctrl.controls['ctrlStrength'].enable();
                ctrl.controls['ctrlExpediency'].enable();
                ctrl.controls['confidenceLvl'].enable();
                ctrl.controls['impactNature'].enable();
                ctrl.controls['existingControls'].enable();
              }
            );
          }
        );
      }
    });

    this.form = this.formBuilder.group({
      hazardStatement: [
        { value: data.risk.hazardStatement, disabled: true },
        [Validators.required],
      ],
      consequenceStatement: [
        { value: data.risk.consequenceStatement, disabled: true },
        [Validators.required],
      ],
      event: [
        { value: data.risk.event, disabled: true },
        [Validators.required],
      ],
      likelihood: [{ value: '', disabled: true }, [Validators.required]],
    });

    this.riskInfoService.likelihood = this.form.get('likelihood').valueChanges;
    this.form.get('likelihood').setValue(data.risk.likelihood);

    const infrastructureFg: FormArray = this.formBuilder.array([]);
    const multiImpAreaSignals = signal<Signal<any>[]>([]);
    for (const key in data.risk.infrastructures) {
      const inf: InfrastructureDetail = data.risk.infrastructures[key];
      this.maxConsequences.push(inf.maxConsequence);
      const impactedAreas: string[] = [];
      const impactedAreasFg: FormArray = this.formBuilder.array([]);
      inf.impactedAreas.forEach((impactArea) => {
        impactedAreas.push(impactArea.impactArea);
        const group = this.riskInfoService.setFormGroupControlValueChanges(
          key,
          this.formBuilder,
          impactArea.impactArea,
          true
        );
        impactedAreasFg.push(group);
        const formGrp = group.controls[impactArea.impactArea] as FormGroup;
        formGrp.controls['consequence'].setValue(
          this.riskInfoService.getConsequenceValue(impactArea.consequence)
        );
        formGrp.controls['structures'].setValue(impactArea.structures);
        formGrp.controls['ctrlStrength'].setValue(impactArea.controlStrength);
        formGrp.controls['ctrlExpediency'].setValue(
          impactArea.controlExpediency
        );
        formGrp.controls['confidenceLvl'].setValue(impactArea.confidenceLevel);
        formGrp.controls['impactNature'].setValue(impactArea.natureOfImpactTo);
        formGrp.controls['existingControls'].setValue(
          impactArea.existingControls
        );
      });
      const fGrr: FormGroup = this.formBuilder.group({
        [key]: this.formBuilder.group({
          impactArea: [
            { value: inf.impactArea, disabled: true },
            [Validators.required],
          ],
          feature: [
            { value: inf.feature, disabled: true },
            [Validators.required],
          ],
          impactedAreas: impactedAreasFg,
        }),
      });
      infrastructureFg.push(fGrr);
      multiImpAreaSignals.update((items) => [
        ...items,
        toSignal(fGrr.get([key, 'impactedAreas'])!.valueChanges),
      ]);

      this.multipleImpactedAreas.set(key, impactedAreas);
    }

    effect(
      () => {
        if (
          this.mode() === 'edit' &&
          multiImpAreaSignals() &&
          !multiImpAreaSignals().some((i) =>
            Object.values(i).some((value) => value === null)
          )
        ) {
          multiImpAreaSignals().forEach((v, index) => {
            this.maxConsequences[index] =
              this.riskInfoService.getMaxConsequence(v());
          });
        }
      },
      { allowSignalWrites: true }
    );

    this.form.setControl('infrastructure', infrastructureFg);
  }

  get riskInfrastructures(): string[] {
    return Object.keys(this.riskInfo.infrastructures);
  }

  get infrastructures(): FormArray {
    return this.form.get('infrastructure') as FormArray;
  }

  get infrastructureControls(): any[] {
    return this.infrastructures.controls;
  }

  getFormGroup(group: any): FormGroup {
    return group as FormGroup;
  }

  getGroupKey(group: any) {
    return Object.keys(group.controls)[0];
  }

  getImpactedAreas(key: string): FormArray {
    const frmGr: FormGroup = this.infrastructures.controls.find((c) =>
      c.get(key)
    ) as FormGroup;
    return frmGr.get(key).get('impactedAreas') as FormArray;
  }

  getImpactedAreaControls(key: string): any[] {
    return this.getImpactedAreas(key).controls;
  }

  get impactedAreas(): FormArray {
    return this.form.get('impactedAreas') as FormArray;
  }

  getInfrastructure(key: string): MultiSelectLabel[] {
    switch (key) {
      case 'Transport/Bridges':
        return this.riskInfo.infrastructures[key].data!.features.map((f) => {
          const prop = f.properties as BridgeProperties;
          return {
            id: f.id,
            geometry: f.geometry,
            displayName: `${prop.full_street_name} (${prop.one_way})`,
          };
        });
      case 'Buildings':
        return this.riskInfo.infrastructures[key].data!.features.map((f) => {
          const prop = f.properties as BuildingProperties;
          return {
            id: f.id,
            geometry: f.geometry,
            displayName: `${prop.build_id} ${prop.address}`,
          };
        });
      default:
        return null;
    }
  }

  //TODO
  selectOnMap(groupKey: string) {
    console.log(groupKey);
  }

  cancel(): void {
    this.updateEvent.emit(CreateEvent.cancel<Risk>());
    this.dialogRef.close();
  }

  submit() {
    const recordToUpdate: Risk = this.riskWizardService.allRisks$.value.find(
      (r) => r.data.riskRef === this.riskInfo.riskRef
    );

    recordToUpdate.data.hazardStatement =
      this.form.get('hazardStatement').value;
    recordToUpdate.data.consequenceStatement = this.form.get(
      'consequenceStatement'
    ).value;
    recordToUpdate.data.likelihood = this.form.get('likelihood').value;
    recordToUpdate.data.infrastructures = this._getInfrastructure(
      recordToUpdate.data
    );

    this.updateEvent.emit(CreateEvent.create<Risk>(recordToUpdate));
    this.dialogRef.close();
  }

  _getInfrastructure(data: RiskDetail): InfrastructureDetails {
    return this.infrastructures.controls.reduce(
      (inf: InfrastructureDetails, fg: FormGroup, i: number) => {
        let key: string = Object.keys(fg.controls)[0];
        const fGroup: FormGroup = fg.controls[key] as FormGroup;
        inf[key] = {
          data: data.infrastructures[key].data,
          maxConsequence: this.maxConsequences[i],
          impactArea: fGroup.controls['impactArea'].value,
          feature: fGroup.controls['feature'].value,
          impactedAreas: this._getImpactedAreaConsequenceArray(key),
        };

        return inf;
      },
      {}
    );
  }

  _getImpactedAreaConsequenceArray(infKey: string): ImpactAreaRisDetails[] {
    return this.getImpactedAreas(infKey).controls.reduce(
      (acc: ImpactAreaRisDetails[], formGroup: FormGroup) => {
        let key: string = Object.keys(formGroup.controls)[0];
        const fGroup: FormGroup = formGroup.controls[key] as FormGroup;
        acc.push({
          impactArea: key,
          consequence: this.riskInfoService.getConsequenceName(
            fGroup.controls['consequence'].value
          ),
          structures: fGroup.controls['structures'].value,
          controlStrength: fGroup.controls['ctrlStrength'].value,
          controlExpediency: fGroup.controls['ctrlExpediency'].value,
          confidenceLevel: fGroup.controls['confidenceLvl'].value,
          natureOfImpactTo: fGroup.controls['impactNature'].value,
          existingControls: fGroup.controls['existingControls'].value,
          controlEffectiveness: convertToTitleCase(
            this.riskInfoService.likelihoodState.get(infKey).get(key)
              .ctrlEffectiveness$.value.value
          ),
          likelihoodAfterControl: convertToTitleCase(
            this.riskInfoService.likelihoodState.get(infKey).get(key)
              .likelihoodAfterCtrl$.value.value
          ),
          risk: convertToTitleCase(
            this.riskInfoService.likelihoodState.get(infKey).get(key).riskLevel$
              .value.value
          ),
          priority: this.riskInfoService.likelihoodState
            .get(infKey)
            .get(key)
            .priorityLevel$.value.rating.toString(),
        });

        return acc;
      },
      []
    );
  }

  impactAreaChange($event: MultiSelectChangeEvent, key: string) {
    const existing = this.riskInfo.infrastructures[key].impactArea.includes(
      $event.itemValue
    );
    if (existing) {
      this.modalService
        .showConfirmation(
          `Are you sure.<br>This will clear out existing data for Impact Area: ****`
        )
        .subscribe((next) => {
          if (!next) {
            (this.form.get('infrastructure') as FormArray).controls.forEach(
              (inf: FormGroup) => {
                if (Object.keys(inf.controls)[0] === key) {
                  let infCtrl = Object.values(inf.controls)[0] as FormGroup;
                  infCtrl
                    .get('impactArea')
                    .setValue(this.multipleImpactedAreas.get(key));
                }
              }
            );
          } else {
            this._removeExistingImpactedArea(key, $event.itemValue);
          }
        });
    } else {
      const isAddition = !this.multipleImpactedAreas
        .get(key)
        .includes($event.itemValue);
      if (isAddition) {
        this.getImpactedAreas(key).push(
          this.riskInfoService.setFormGroupControlValueChanges(
            key,
            this.formBuilder,
            $event.itemValue
          )
        );
        this.multipleImpactedAreas.set(key, $event.value);
      } else {
        this._removeExistingImpactedArea(key, $event.itemValue);
      }
    }
  }

  exportToCsv() {
    const headings: string =
      'Risk Ref,Hazard Type,Analysis Zone,Locations,Lifeline(s),Hazard Statement,Consequence Statement,Event,Event Likelihood,Lifeline,Domain,Max Consequence,Impact Area,Consequence,Structures,Existing Controls,Control Strength,Control Expediency,Confidence Level,Nature Of Impact To,Control Effectiveness,Likelihood After Control,Risk,Priority';
    const {
      riskRef,
      hazardType,
      analysisZone,
      locations,
      infrastructures,
      hazardStatement,
      consequenceStatement,
      event,
      likelihood,
    } = this.riskInfo;

    const infs = Object.keys(infrastructures);
    const firstInf = `${riskRef},${hazardType},${analysisZone},${locations},${infs.join(
      '; '
    )},${this._replaceNewLine(hazardStatement)},${this._replaceNewLine(
      consequenceStatement
    )},${event},${likelihood}`;
    const secondInf = ',,,,,,,,,';
    const content: string[] = [headings];

    infs.forEach((key, i) => {
      const { impactArea, feature, impactedAreas, maxConsequence } =
        infrastructures[key];
      const maxConsNam = RiskWizard.CONSEQUENCE.find(
        (c) => c.value === parseInt(maxConsequence)
      ).name;
      const infDetails = `${key},${feature},${impactArea.join(
        '; '
      )},${maxConsNam}`;
      const altDetails = `,,`;

      let row: string =
        i === 0 ? `${firstInf},${infDetails}` : `${secondInf},${infDetails}`;
      impactedAreas.forEach((ia, index) => {
        const {
          consequence,
          structures,
          controlStrength,
          controlExpediency,
          confidenceLevel,
          natureOfImpactTo,
          existingControls,
          controlEffectiveness,
          likelihoodAfterControl,
          risk,
          priority,
        } = ia;

        const structDName = structures.map((a) => a.displayName).join('; ');
        const impArea: string = `${
          impactArea[index]
        },${consequence},${structDName},${this._replaceNewLine(
          existingControls
        )},${controlStrength},${controlExpediency},${confidenceLevel},${this._replaceNewLine(
          natureOfImpactTo
        )},${controlEffectiveness},${likelihoodAfterControl},${risk},${priority}`;

        if (index === 0) {
          content.push(`${row},${impArea}`);
        } else {
          content.push(`${secondInf},${altDetails},${impArea}`);
        }
      });
    });
    console.log(content.join(' \n'));
    this.exporterService.exportRiskAsCSV(content, riskRef);
  }

  deleteRisk() {
    this.modalService.showConfirmDelete(
      'Risk Information',
      this.riskInfo.riskRef
    );
    //TODO
  }

  _removeExistingImpactedArea(infrastructure: string, impactArea: string) {
    this.riskInfoService.removeImpactedArea(
      infrastructure,
      impactArea,
      this.getImpactedAreas(infrastructure)
    );
    const index = this.multipleImpactedAreas
      .get(infrastructure)
      .indexOf(impactArea);
    this.multipleImpactedAreas.get(infrastructure).splice(index, 1);
  }

  _replaceNewLine(str: string): string {
    return str.replaceAll('\n', ' ').replaceAll(',', ':');
  }
}
