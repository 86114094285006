import { Signal, computed } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { UntypedFormGroup } from '@angular/forms';

type ObjectConstraint = { [key: string]: any };

export function generateComputedModel<T extends ObjectConstraint>(
  baseModel: T,
  form: UntypedFormGroup
): Signal<T> {
  const changes: Signal<any> = toSignal(form.valueChanges);

  return computed(() => {
    let _updatedModel = { ...baseModel };

    if (changes() == null) {
      return _updatedModel;
    }

    Object.keys(_updatedModel).forEach((key) => {
      (_updatedModel as any)[key] = changes()[key];
    });

    return _updatedModel;
  }, {});
}

export function convertToTitleCase(txt: string): string {
  if (txt === '') {
    return '';
  }
  return txt
    .toLowerCase()
    .split(' ')
    .map((word) => word[0].toUpperCase() + word.slice(1))
    .join(' ');
}
