<div #draggableContainer class="table-height" xmlns="http://www.w3.org/1999/html">
  <div appDraggable (heightChange)="onHeightChange($event)" class="tab">
    <button appTooltip="Risk Info Table" tooltipPosition="top" (click)="close()">
      <span><i class="fa fa-lg fa-table"></i></span>
    </button>
  </div>
  <p-table [value]="risks"  styleClass="p-datatable-gridlines mt-3" dataKey="riskRef" #riskTable
           [scrollable]="true" sortMode="multiple" [tableStyle]="{ 'min-width': '90dvw', 'font-size': '0.7rem'}"
           [globalFilterFields]="
           ['hazardStatement', 'consequenceStatement', 'riskTreatmentStrategies', 'riskTreatmentActions', 'notes']"
           [scrollHeight]="tableHeight()" [ngStyle]="{ height: tableHeight() }" [expandedRowKeys]="expandedRows">
    <ng-template pTemplate="caption">
      <span class="input-with-icon ml-2">
        <p-iconField iconPosition="left" class="ml-auto">
          <p-inputIcon>
            <i class="pi pi-search"></i>
          </p-inputIcon>
          <input #globalFilter pInputText type="text" placeholder="Search keyword"
                 (input)="riskTable.filterGlobal($event.target.value, 'contains')"/>
        </p-iconField>

        @if (globalFilter.value.length > 0) {
          <button class="" (click)="riskTable.clear(); globalFilter.value = ''"><i class="fa fa-times"></i></button>
        }
      </span>
      <span class="flex gap-2 mr-2">
        <button (click)="expandAll()" class="bg-sky-600 hover:bg-sky-800 text-white py-2 px-4 border border-sky-600">
          <i class="fa-solid fa-plus fa-lg"></i> Expand All
        </button>
        <button (click)="collapseAll()" class="bg-sky-600 hover:bg-sky-800 text-white py-2 px-4 border border-sky-600">
          <i class="fa-solid fa-minus fa-lg"></i> Collapse All
        </button>
        <button appTooltip="Export as CSV" tooltipPosition="left" (click)="exportToCSV()"
                class="bg-sky-600 hover:bg-sky-800 text-white py-2 px-4 border border-sky-600">
        <i class="fa fa-download fa-lg"></i>
      </button>
      </span>

    </ng-template>
    <ng-template pTemplate="header">
      <tr>
        <th style="min-width: 50px" pFrozenColumn></th>
        <th pSortableColumn="riskRef" style="min-width: 75px; display: flex; justify-content: center;" pFrozenColumn>
          Risk Ref
          <p-sortIcon field="riskRef" />
        </th>
        <th style="min-width: 100px">Hazard Type
          <p-sortIcon field="hazardType" />
        </th>
        <th style="min-width: 100px">Analysis Zone
          <p-sortIcon field="analysisZone" />
        </th>
        <th style="min-width: 200px">Hazard Statement</th>
        <th style="min-width: 200px">Consequence Statement</th>
        <th style="min-width: 200px">Event</th>
        <th style="min-width: 75px">Likelihood
          <p-sortIcon field="likelihood" />
        </th>
        <th style="min-width: 75px" pFrozenColumn alignFrozen="right">Actions</th>
      </tr>
      <tr>
        <th pFrozenColumn></th>
        <th pFrozenColumn>
          <p-columnFilter matchMode="contains" type="text" field="riskRef"/>
        </th>
        <th>
          <p-columnFilter field="hazardType" matchMode="in" [showMenu]="false">
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-multiSelect appendTo="body" [ngModel]="value" [style]="{'font-size': '0.7rem'}"
                [options]="hazardTypes" placeholder="Any"
                (onChange)="filter($event.value); console.log($event.value)">
                <ng-template let-option pTemplate="item">
                  <div class="inline-block vertical-align-middle drop-down">
                    <span class="ml-1 mt-1">{{ option }}</span>
                  </div>
                </ng-template>
              </p-multiSelect>
            </ng-template>
          </p-columnFilter>
        </th>
        <th>
          <p-columnFilter field="analysisZone" matchMode="in" [showMenu]="false">
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-multiSelect appendTo="body" [ngModel]="value" [style]="{'font-size': '0.7rem'}"
                             [options]="analysisZones" placeholder="Any"
                             (onChange)="filter($event.value); console.log($event.value)">
                <ng-template let-option pTemplate="item">
                  <div class="inline-block vertical-align-middle drop-down">
                    <span class="ml-1 mt-1">{{ option }}</span>
                  </div>
                </ng-template>
              </p-multiSelect>
            </ng-template>
          </p-columnFilter>
        </th>
        <th></th>
        <th></th>
        <th>
          <p-columnFilter field="event" matchMode="in" [showMenu]="false">
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-multiSelect appendTo="body" [ngModel]="value" [style]="{'font-size': '0.7rem'}"
                             [options]="event" placeholder="Any"
                             (onChange)="filter($event.value); console.log($event.value)">
                <ng-template let-option pTemplate="item">
                  <div class="inline-block vertical-align-middle drop-down">
                    <span class="ml-1 mt-1">{{ option }}</span>
                  </div>
                </ng-template>
              </p-multiSelect>
            </ng-template>
          </p-columnFilter>
        </th>
        <th>
          <p-columnFilter field="likelihood" matchMode="in" [showMenu]="false">
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-multiSelect appendTo="body" [ngModel]="value" [style]="{'font-size': '0.7rem'}"
                             [options]="likelihood" placeholder="Any"
                             (onChange)="filter($event.value); console.log($event.value)">
                <ng-template let-option pTemplate="item">
                  <div class="inline-block vertical-align-middle drop-down">
                    <span class="ml-1 mt-1">{{ option }}</span>
                  </div>
                </ng-template>
              </p-multiSelect>
            </ng-template>
          </p-columnFilter>
        </th>
        <th pFrozenColumn alignFrozen="right"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-risk let-expanded="expanded">
      <tr>
        <td pFrozenColumn>
          <p-button type="button" pRipple [pRowToggler]="risk" [text]="true" [rounded]="true" [plain]="true"
                    [icon]="expanded ? 'fa-solid fa-chevron-down fa-lg' : 'fa-solid fa-chevron-right fa-lg'" />
        </td>
        <td pFrozenColumn>{{risk.riskRef}}</td>
        <td>{{risk.hazardType}}</td>
        <td>{{risk.analysisZone}}</td>
        <td>{{risk.hazardStatement}}</td>
        <td>{{risk.consequenceStatement}}</td>
        <td>{{risk.event}}</td>
        <td>{{risk.likelihood}}</td>
        <td style="width: 5rem" pFrozenColumn alignFrozen="right">
          <div class="flex flex-row gap-2">
            <button (click)="viewRiskDetail(risk)" appTooltip="View Risk Details" tooltipPosition="left" >
              <i class="fa-solid fa-eye fa-xl"></i>
            </button>
            <button (click)="riskTreatment(risk)" appTooltip="Risk Treatment" tooltipPosition="left" >
              <i class="fa-solid fa-laptop-medical fa-xl"></i>
            </button>
            <button (click)="zoomToRiskLocation(risk)" appTooltip="Zoom to location" tooltipPosition="left" >
              <i class="fa-solid fa-magnifying-glass fa-xl"></i>
            </button>
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="rowexpansion" let-risk>
      <tr>
        <td colspan="13">
          <div class="p-3">
            <p-accordion [multiple]="true">
              <p-accordionTab header="Infrastructure(s)">
                <p-accordion [multiple]="true">
                  @for (inf of risk.infrastructures | keyvalue; track inf) {
                    <p-accordionTab header="{{inf.key}}">
                      <p-table [value]="inf.value.impactedAreas">
                        <ng-template pTemplate="header">
                          <tr>
                            <th>Consequence</th>
                            <th>Control Strength</th>
                            <th>Control Expediency</th>
                            <th>Confidence Level</th>
                            <th>Nature Of Impact To</th>
                            <th>Existing Controls</th>
                            <th>Control Effectiveness</th>
                            <th>Likelihood After Control</th>
                            <th>Risk</th>
                            <th>Priority</th>
                          </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-impactArea>
                          <tr>
                            <td>{{impactArea.consequence}}</td>
                            <td>{{impactArea.controlStrength}}</td>
                            <td>{{impactArea.controlExpediency}}</td>
                            <td>{{impactArea.confidenceLevel}}</td>
                            <td>{{impactArea.natureOfImpactTo}}</td>
                            <td>{{impactArea.existingControls}}</td>
                            <td [style.background-color]="riskInfoService.getCtrlEffectivenessColour(impactArea.controlStrength, impactArea.controlExpediency)">
                              {{impactArea.controlEffectiveness}}
                            </td>
                            <td [style.background-color]="riskInfoService.getLikelihoodColour(risk.likelihood,impactArea.controlEffectiveness)">
                              {{impactArea.likelihoodAfterControl}}
                            </td>
                            <td [style.background-color]="riskInfoService.getRiskLevelColour(impactArea.likelihoodAfterControl, impactArea.consequence)">
                              {{impactArea.risk}}
                            </td>
                            <td [style.background-color]="riskInfoService.getPriorityLevelColour(impactArea.confidenceLevel, impactArea.consequence,impactArea.likelihoodAfterControl)">
                              {{impactArea.priority}}
                            </td>
                          </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                          <tr>
                            <td colspan="6">There are no Imapct Areas for this Risk yet.</td>
                          </tr>
                        </ng-template>
                      </p-table>
                    </p-accordionTab>
                  }
                </p-accordion>
              </p-accordionTab>
              <p-accordionTab header="Treatment(S)">
                <p-table [value]="risk.treatments">
                  <ng-template pTemplate="header">
                    <tr>
                      <th style="min-width: 100px">Lifeline
                        <p-sortIcon field="infrastructure" />
                      </th>
                      <th style="min-width: 100px">Feature
                        <p-sortIcon field="feature" />
                      </th>
                      <th style="min-width: 100px">Domain
                        <p-sortIcon field="impactArea" />
                      </th>
                      <th style="min-width: 100px">Treatment Options
                        <p-sortIcon field="treatmentOptions" />
                      </th>
                      <th style="min-width: 200px">Risk Treatment Strategies
                        <p-sortIcon field="riskTreatmentStrategies" />
                      </th>
                      <th style="min-width: 200px">Risk Treatment Actions</th>
                      <th style="min-width: 100px">Risk Ranking
                        <p-sortIcon field="riskRanking" />
                      </th>
                      <th style="min-width: 100px">Municipalities</th>
                      <th style="min-width: 100px">Region
                        <p-sortIcon field="region" />
                      </th>
                      <th style="min-width: 200px">Notes</th>
                      <th style="min-width: 200px">Reference Material</th>
                      <th style="min-width: 200px">Prevention Mitigation Management Agency
                        <p-sortIcon field="preventionMitigationManagementAgency" />
                      </th>
                      <th style="min-width: 200px">Preparedness Management Agency
                        <p-sortIcon field="preparednessManagementAgency" />
                      </th>
                      <th style="min-width: 200px">Response Management Agency</th>
                    </tr>
                    <tr>
                      <th>
                        <p-columnFilter field="infrastructure" matchMode="in" [showMenu]="false">
                          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                            <p-multiSelect appendTo="body" [ngModel]="value" [style]="{'font-size': '0.7rem'}"
                                           [options]="infrastructure" placeholder="Any"
                                           (onChange)="filter($event.value); console.log($event.value)">
                              <ng-template let-option pTemplate="item">
                                <div class="inline-block vertical-align-middle drop-down">
                                  <span class="ml-1 mt-1">{{ option }}</span>
                                </div>
                              </ng-template>
                            </p-multiSelect>
                          </ng-template>
                        </p-columnFilter>
                      </th>
                      <th>
                        <p-columnFilter field="feature" matchMode="in" [showMenu]="false">
                          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                            <p-multiSelect appendTo="body" [ngModel]="value" [style]="{'font-size': '0.7rem'}"
                                           [options]="feature" placeholder="Any"
                                           (onChange)="filter($event.value); console.log($event.value)">
                              <ng-template let-option pTemplate="item">
                                <div class="inline-block vertical-align-middle drop-down">
                                  <span class="ml-1 mt-1">{{ option }}</span>
                                </div>
                              </ng-template>
                            </p-multiSelect>
                          </ng-template>
                        </p-columnFilter>
                      </th>
                      <th>
                        <p-columnFilter field="impactArea" matchMode="in" [showMenu]="false">
                          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                            <p-multiSelect appendTo="body" [ngModel]="value" [style]="{'font-size': '0.7rem'}"
                                           [options]="impactArea" placeholder="Any"
                                           (onChange)="filter($event.value); console.log($event.value)">
                              <ng-template let-option pTemplate="item">
                                <div class="inline-block vertical-align-middle drop-down">
                                  <span class="ml-1 mt-1">{{ option }}</span>
                                </div>
                              </ng-template>
                            </p-multiSelect>
                          </ng-template>
                        </p-columnFilter>
                      </th>
                      <th>
                        <p-columnFilter field="treatmentOptions" matchMode="in" [showMenu]="false">
                          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                            <p-multiSelect appendTo="body" [ngModel]="value" [style]="{'font-size': '0.7rem'}"
                                           [options]="treatmentOpt" placeholder="Any"
                                           (onChange)="filter($event.value); console.log($event.value)">
                              <ng-template let-option pTemplate="item">
                                <div class="inline-block vertical-align-middle drop-down">
                                  <span class="ml-1 mt-1">{{ option }}</span>
                                </div>
                              </ng-template>
                            </p-multiSelect>
                          </ng-template>
                        </p-columnFilter>
                      </th>
                      <th>
                        <p-columnFilter field="riskTreatmentStrategies" matchMode="in" [showMenu]="false">
                          <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                            <p-multiSelect appendTo="body" [ngModel]="value" [style]="{'font-size': '0.7rem'}"
                                           [options]="treatmentStrategy" placeholder="Any"
                                           (onChange)="filter($event.value); console.log($event.value)">
                              <ng-template let-option pTemplate="item">
                                <div class="inline-block vertical-align-middle drop-down">
                                  <span class="ml-1 mt-1">{{ option }}</span>
                                </div>
                              </ng-template>
                            </p-multiSelect>
                          </ng-template>
                        </p-columnFilter>
                      </th>
                      <th>
                        <p-columnFilter matchMode="contains" type="text" field="riskTreatmentActions"/>
                      </th>
                      <th></th>
                      <th>
                        <p-columnFilter matchMode="contains" type="text" field="municipalities"/>
                      </th>
                      <th>
                        <p-columnFilter matchMode="contains" type="text" field="region"/>
                      </th>
                      <th></th>
                      <th></th>
                      <th>
                        <p-columnFilter matchMode="contains" type="text" field="preventionMitigationManagementAgency"/>
                      </th>
                      <th>
                        <p-columnFilter matchMode="contains" type="text" field="preparednessManagementAgency"/>
                      </th>
                      <th>
                        <p-columnFilter matchMode="contains" type="text" field="responseManagementAgency"/>
                      </th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-treatment>
                    <tr>
                      <td>{{treatment.infrastructure}}</td>
                      <td>{{treatment.feature}}</td>
                      <td>{{treatment.impactAreas}}</td>
                      <td>{{treatment.treatmentOptions}}</td>
                      <td>{{treatment.riskTreatmentStrategies}}</td>
                      <td>{{treatment.riskTreatmentActions}}</td>
                      <td>{{treatment.riskRanking}}</td>
                      <td>{{treatment.municipalities}}</td>
                      <td>{{treatment.region}}</td>
                      <td>{{treatment.notes}}</td>
                      <td>{{treatment.referenceMaterial}}</td>
                      <td>{{treatment.preventionMitigationManagementAgency}}</td>
                      <td>{{treatment.preparednessManagementAgency}}</td>
                      <td>{{treatment.responseManagementAgency}}</td>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="emptymessage">
                    <tr>
                      <td colspan="6">There are no Treatments for this Risk yet.</td>
                    </tr>
                  </ng-template>
                </p-table>
              </p-accordionTab>
            </p-accordion>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>


